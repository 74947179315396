
import 'bulma/sass/elements/content.sass';
import BMessage from 'buetify/lib/components/message/BMessage';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'buetify-summary',
	components: {
		BMessage
	}
});
